// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/www/wwwroot/release/poppaaws-new-web/src/models/chat';
import model_2 from '/www/wwwroot/release/poppaaws-new-web/src/models/idStore';
import model_3 from '/www/wwwroot/release/poppaaws-new-web/src/models/live2d';
import model_4 from '/www/wwwroot/release/poppaaws-new-web/src/models/messageStore';
import model_5 from '/www/wwwroot/release/poppaaws-new-web/src/models/sticker';
import model_6 from '/www/wwwroot/release/poppaaws-new-web/src/.umi-production/plugin-initialState/@@initialState';

export const models = {
model_1: { namespace: 'chat', model: model_1 },
model_2: { namespace: 'idStore', model: model_2 },
model_3: { namespace: 'live2d', model: model_3 },
model_4: { namespace: 'messageStore', model: model_4 },
model_5: { namespace: 'sticker', model: model_5 },
model_6: { namespace: '@@initialState', model: model_6 },
} as const
